.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main-nav{
  text-align: center;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 1px solid black;
  padding-top: 8px;
  padding-bottom: 1px;
  color: white;
  background-color: black;
  
}

.main-nav a{
  color: white;
  text-decoration: none;
}

.main-nav a:hover{
  background-color: darkgray;
  color: black;
}

.main-nav ul{
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5em;
}

.header{
  background-color: black;
}

.header1{
  font-size: 48px;
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
  padding-bottom: 8px;
}

.mainBG-Teams{
  height: 60%;
  justify-content: space-evenly;
  background-color: #585858;
  padding-top: 14vh;
}

.teamsContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex: 0 0 auto;
  background-color: #585858;
  /* padding: 25px; */
  /* padding-top: -10vh; SEE IF THIS IS REALLY NOT ALLOWED */
}


.p1Team{
  /* margin: auto 15%; */
  height: 450px;
  width: 250px;
  display: flex;
  align-items: center;
  padding-top: 1%;
  flex-direction: column;
  background-color: black;
  box-sizing: border-box;
}

.p2Team{
  /* margin: auto 75%; */
  height: 450px;
  width: 250px;
  display: flex;
  align-items: center;
  padding-top: 1%;
  flex-direction: column;
  background-color: black;
  box-sizing: border-box;
}

.teamBtn{
    display: inline-block;
    padding: 20px;
    margin-right: 95px;
    line-height: 100%;
}

.teamSelect{
  font-weight: 500;
  color:lightgray;
}


.playerName{
  display: grid;
  font-weight: 500;
  place-items: center;
  height: 30px;
  margin: auto;
  justify-content: center;
  padding: 5px;
  color: lightgray;
  text-align: center;
}

.submitButton{
  background-color: #333;
  color: lightgray;
  padding: 10px 24px;
  border-radius: 8px;
  justify-content: center;
  transition: all 0.2s;
  font-weight: 500;
}

.submitButton:hover{
  background-color: dimgray;
  cursor: pointer;
}


.order{
  color: lightgray;
  /* display: grid;
  place-items: center; */
  margin-top: -10px;
  /* margin-left: 100px; */
  position: relative;
  float: right;
}

.order1{
  margin: 0 40%;
}
 
@media(max-width: 900px){

  .fullContainer{
    width: 100vw;
    /* height: 100%; */
  }

  .mainBG-Teams{
      flex-direction: column;
      height: 100%;
      width: 100vw;
      padding-top: 0;
  }

  .teamsContainer{
      /* margin-top: -75px; */
      flex-direction: column;
  }

  .SGImg{
      display: none;
  }

  /* .breaker{
      display: show;
  } */
}

@media(min-width: 901px){
  /* .SGImg{
      display: show;
  } */

  .breaker{
      display: none;
  }
}


@media(max-width: 900px){
  .teamBtn{
    margin-top: 15px;
  }
}