/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
/* article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
} */ 



.mainBG{
    height: 75vh;
    display: flex;
    background-color: #585858;
    margin: 0;
    justify-content: space-evenly;
}



.backgroundColor{
    background-color: #585858;
    margin: 0;
    padding-bottom: 100px;
}

.table{
    /* display: flex; */
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    border-collapse: separate;
    border-spacing: .5px 5px;
    width: 75%;
    /* border: 10px black;
    background-color: darkgray;
    justify-content: center;
    text-align: center; */
}

.tbl-hdr{
    width: 200px;
    text-align: center;
    justify-content: center;
    padding: 5px; 
    border: 1px solid black;
    background-color: #333;
    color: lightgray;
    font-weight: 500;
    font-size: 20px;
}

.img{
    box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.2);
}


.centerpls{
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 5px auto;
}

.tbl-event{
    width: 200px;
    text-align: center;
    padding: 5px; 
    border: 1px solid black;
    background-color: #333;
    color: lightgray;
    font-weight: 500;
    height: 100px;
    font-size: 20px;
}

.submitButton{
    background-color: #333;
    color: lightgray;
    padding: 10px 24px;
    border-radius: 8px;
    justify-content: center;
    transition: all 0.2s;
    font-weight: 500;
}
  
.submitButton:hover{
    background-color: dimgray;
    cursor: pointer;
}

.submitCenter{
    display: grid;
    place-items: center;
    justify-content: center;
    background-color: #585858;

    /* margin: -30px -1px; */
}

.fullContainer{
    display: flex;
    flex-direction: column;
}


@media(max-width: 900px){
    .submitCenter{
        padding-top: 10px;
        display: grid;
        place-items: center;
        /* margin-bottom: 0;  */
    }

    .centerpls{
        font-size: 0px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 5px auto;
    } 

    .tbl-hdr{
        font-size: 12px;
    }

    .tbl-event{
        font-size: 9px;
    }
    /* .mainBG-VODs{
        padding-top: 100px;
    } */

}