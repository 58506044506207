/* .rainbow-m-horizontal_xx-large {
    margin-right: 3rem;
    margin-left: 3rem;
}
.rainbow-p-horizontal_xx-large {
    padding-right: 3rem;
    padding-left: 3rem;
}
.rainbow-p-vertical_large {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.date{
    width: 20%;
    justify-content: center;
} */

.rainbow-m-horizontal_xx-large {
    margin-right: 3rem;
    margin-left: 3rem;
}
.rainbow-p-horizontal_xx-large {
    padding-right: 3rem;
    padding-left: 3rem;
}
.rainbow-p-vertical_large {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
 
.date{
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
}
 
.dateLabel{
    font-size: 20px;
    color: white;
}
 
.dateAndEvent{
    margin-top: -6vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
 
.event{
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
}
 
.eventLabel{
    font-size: 20px;
}
.eventInput{
    color: black;
    width: 300px;
    height: 32px;
    padding: 3px;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
}
 
.link{
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
}
 
.linkLabel{
    font-size: 20px;
}
.linkInput{
    color: black;
    width: 350px;
    height: 32px;
    padding: 3px;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
}


.mainBG-Submit{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-evenly;
    background-color: #585858;
    width: 100%;
    padding-bottom: 200px;
}


.submitCenter{
    /* padding-bottom: 2.5vh; */
    display: grid;
    place-items: center;
    justify-content: center;
    background-color: #585858;
    width: 100%;
}


.teamsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex: 0 0 auto;
    background-color: #585858;
    /* padding: 25px; */
    padding-top: -10vh;
  }

/* @media (max-width: 1000px) and (min-height: 0px) and (max-height: 575px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 30%;
    }

  }


@media (max-width: 1000px) and (min-height: 576px) and (max-height: 685px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 95vh;
    }

  }


@media (max-width: 1000px) and (min-height: 686px) and (max-height: 725px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 85vh;
    }

  }

@media (max-width: 1000px) and (min-height: 726px) and (max-height: 800px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 75vh;
    }
  }

  @media (max-width: 1000px) and (min-height: 801px) and (max-height: 900px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 60vh;
    }
  } */



@media(max-width: 900px){

    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .mainBG-Submit{
        flex-direction: column;
        height: 100%;
        padding-bottom: 300px;
    }
    .teamsContainer{
        flex-direction: column;
        margin-top: 10px;
    }

    .SGImg{
        display: none;
    }

    .breaker{
        display: show;
    }
}

@media(min-width: 901px){
    .SGImg{
        display: show;
    }

    .breaker{
        display: none;
    }
}


@media (min-width: 1001px) {
    .mainBG-Submit{
        flex-direction: row;
    }
}

@media (min-width: 901px) and (max-width:999px)and (min-height: 576px){
    .dateAndEvent{
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
    }
}

@media (min-width:1000px) and (min-height: 576px){
    .dateAndEvent{
        display: flex;
        flex-direction: row;
        margin-top: 1vh;
    }
    .mainBG-Submit{
        flex-direction: column;
    }
}

@media (min-width: 750px) and (min-height: 900px){
    .dateAndEvent{
        display: flex;
        flex-direction: row;
        margin-top: 1vh;
    }
    .mainBG-Submit{
        flex-direction: column;
    }
}